import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { dispatch, getters } = useStore()
  const state = reactive({
    form: {
      recomendrCode: ''
    }
  })
  const fnNext = async () => {
    const registerForm = Object.assign(getters['user/getRegisterForm'])
    registerForm.recomendrCode = ''
    await dispatch('user/updateRegisterForm', { registerForm })
    await router.push({ path: '/regist/form' })
  }
  const fnRecommender = async () => {
    if (state.form.recomendrCode.length < 6) {
      alert('추천인 코드를 정확히 입력해주세요')
      return false
    }
    const res = await proxy.$UserSvc.postRecommender(state.form)
    alert(res.resultMsg)
    if (res.resultCode === '0000') {
      const registerForm = Object.assign(getters['user/getRegisterForm'])
      registerForm.recomendrCode = state.form.recomendrCode
      await dispatch('user/updateRegisterForm', { registerForm })
      await router.push({ path: '/regist/form' })
    }
  }
  return { ...toRefs(state), fnNext, fnRecommender }
}
