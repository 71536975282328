<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <h1 class="con_ttl center">
            <span>회원 가입</span>
          </h1>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">추천인 등록</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="6자리 추천인 코드 입력"
                        maxlength="6"
                        v-model="form.recomendrCode"
                      />
                    </label>
                    <div class="hint">
                      * 추천인 코드는 우수 회원에게 별도 지급 드립니다.
                    </div>
                    <div class="hint">
                      * 추천인 코드를 등록하시면 추천하신 분께 300P를
                      지급해드립니다.
                    </div>
                    <div class="hint">
                      * 추천인은 최초 회원가입만 등록 가능하며, 등록된 추천인은
                      변경할 수 없습니다.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnNext">건너뛰기</button>
              <button class="btn" @click="fnRecommender">등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'regist-recommend-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
